<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="5" :sm="24">-->
<!--              <a-form-item label="举报人ID" prop="userNo">-->
<!--                <a-input v-model="queryParam.userNo" placeholder="请输入举报人ID" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="5" :sm="24">-->
<!--              <a-form-item label="被举报人ID" prop="toUserNo">-->
<!--                <a-input v-model="queryParam.toUserNo" placeholder="请输入被举报人ID" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="5" :sm="24">
              <a-form-item label="举报类型" prop="type">
                <a-select placeholder="请选择举报类型" v-model="queryParam.type" style="width: 100%" allow-clear>
                  <a-select-option :value="1">用户</a-select-option>
                  <a-select-option :value="2">Live动态</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="处理状态" prop="handleFlag">
                <a-select placeholder="请选择处理状态" v-model="queryParam.handleFlag" style="width: 100%" allow-clear>
                  <a-select-option :value="0">未处理</a-select-option>
                  <a-select-option :value="1">已处理</a-select-option>
<!--                  <a-select-option v-for="(d, index) in dict.type.is_or" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                </a-select>
              </a-form-item>
<!--              <a-form-item label="处理标记" prop="handleFlag">-->
<!--                <a-select placeholder="请选择处理标记" v-model="queryParam.handleFlag" style="width: 100%" allow-clear>-->
<!--                  <a-select-option v-for="(d, index) in dict.type.handle_mark" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
            </a-col>
            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :typeOptions="dict.type.report_type"
        :reasonOptions="dict.type.report"
        :handleFlagOptions="dict.type.handle_mark"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="image" slot-scope="text, record">
            <span v-if="record.image">
                 <ImageViewer class="imageViewer"   v-if="record.image"  ref="previewImg"
                              v-for="(guildLogo,imgIndex) in record.image.split(',')"
                              :imageUrl="guildLogo"
                              preview="图片" />
            </span>
        </span>
        <span slot="businessData" slot-scope="text, record">
            <a type="primary" @click="showModal(record.businessData)">
            查看内容
          </a>
        </span>
        <span slot="content" slot-scope="text, record">
            <a type="primary" @click="showModal(record.content)">
            查看内容
          </a>
        </span>
        <span slot="type" slot-scope="text, record">
          <dict-tag :options="dict.type['report_type']" :value="record.type"/>
        </span>
        <span slot="reason" slot-scope="text, record">
          <dict-tag :options="dict.type['report']" :value="record.reason"/>
        </span>
        <span slot="handleFlag" slot-scope="text, record">
          <dict-tag :options="dict.type['handle_mark']" :value="record.handleFlag"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record" v-if="record.handleFlag === 0">
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            处理
          </a>
        </span>
      </a-table>
      <a-modal
        v-model="this.visible"
        title="内容"
        @cancel="handleCancel">
        <p>{{this.text}}</p>
        <template slot="footer">
          <a-button @click="handleCancel">关闭</a-button>
        </template>

      </a-modal>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageReport,listReport, delReport } from '@/api/biz/report'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ImageViewer from '@/components/Viewer/ImageViewer.vue'
export default {
  name: 'Report',
  components: {
    ImageViewer,
    CreateForm,
  },
  mixins: [tableMixin],
  dicts: ['report_type', 'report', 'handle_mark'],
  data () {
    return {
      visible:false,
      text:'',
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        sourceId: null,
        type: null,
        reason: null,
        content: null,
        image: null,
        handleFlag: null,
        handleMessage: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        // {
        //   title: '举报人ID',
        //   dataIndex: 'userNo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '举报人昵称',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '被举报人ID',
        //   dataIndex: 'toUserNo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '被举报人昵称',
          dataIndex: 'toUserName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报类型',
          dataIndex: 'type',
          customRender: function (value) {
            if (value === 1){
              return "用户"
            }
            if (value === 2){
              return "动态"
            }
            return value
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '动态',
          dataIndex: 'businessData',
          width: 100,
          // scopedSlots:{ customRender: 'businessData' },
          align: 'center'
        },
        {
          title: '描述内容',
          dataIndex: 'content',
          width: 100,
          scopedSlots:{ customRender: 'content' },
          align: 'center'
        },
        {
          title: '举报原因',
          dataIndex: 'reason',
          scopedSlots: { customRender: 'reason' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '举报图片',
          dataIndex: 'image',
          width: 100,
          align: 'center',
          scopedSlots: {customRender: "image"}
        },
        {
          title: '举报时间',
          dataIndex: 'createTime',width: 160,
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理状态',
          dataIndex: 'handleFlag',
          customRender: function (value) {
            if (value === 0){
              return "未处理"
            } else {
              return "已处理"
            }
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理内容',
          dataIndex: 'handleMessage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    showModal(text) {
      this.visible = true;
      this.text =text;
    },
    /** 查询举报列表 */
    getList () {
      this.loading = true
     pageReport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        sourceId: undefined,
        type: undefined,
        reason: undefined,
        content: undefined,
        image: undefined,
        handleFlag: undefined,
        handleMessage: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delReport(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/report/export', {
            ...that.queryParam
          }, `举报_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleCancel(e) {
      console.log(e);
      this.visible = false;
      this.text = "";
    },
  }
}
</script>
<style lang="less" scoped>
//图片样式
.imageViewer{
  width: 48px;height:48px;display: flex;justify-content: center;margin: auto;
}
</style>
